body {
  background-color: black;
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  font-family: "Poppins";
  color: #333;
}

html {
  scroll-behavior: smooth;
}